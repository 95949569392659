.content bdi {
  padding: 10px;
  border-radius: 10px;
}

.MuiFormControl-root.MuiTextField-root.macField input.MuiOutlinedInput-input.MuiInputBase-input {
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f100;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #88888869;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8888889e;
}
